* {
    scrollbar-width: thin;
    scrollbar-color: #15803D #f3f4f600;
    padding: 0;
    margin: 0;
    font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  }

  html {
    background-color: white;
  }

.app_svg, button svg {
    height: 20px;
}

.login_logo {
    width: 30vw;
    padding: 20px;
}

#login_background {
  background-image: url("./assets/images/login_background.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.change-password-bg {
  background-image: url("./assets/images/pexels-adam-lukac-773953.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}